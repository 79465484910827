/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles';

import Footer from './Footer';

const useStyles = makeStyles(theme => ({
    container: {
        minHeight: '100vh',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            minHeight: 'auto',
        },
    },
}));

const Layout = ({ children, footerTitle, href, color, backgroundColor }) => {
    const classes = useStyles();

    // useEffect(() => {
    //     window.addEventListener('resize', () => {
    //         (
    //             document.getElementById(window.location.hash.slice(1)) ||
    //             document.body
    //         ).scrollIntoView();
    //     });
    // }, []);

    return (
        <Box className={classes.container}>
            {children}
            {footerTitle && (
                <Hidden only={['sm', 'xs']}>
                    <Footer
                        footerTitle={footerTitle}
                        href={href}
                        color={color}
                        backgroundColor={backgroundColor}
                    />
                </Hidden>
            )}
        </Box>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    footerTitle: PropTypes.string,
    href: PropTypes.string,
    color: PropTypes.string,
    backgroundColor: PropTypes.string,
};

export default Layout;
