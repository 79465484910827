import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';

import Box from '@material-ui/core/Box';
import MuiLink from '@material-ui/core/Link';

import { useStyles } from '../styles/footer.styles';
import arrow from '../images/icons/arrow.svg';
import whiteArrow from '../images/icons/whiteArrow.svg';

const Footer = ({ color, backgroundColor, footerTitle, href }) => {
    const classes = useStyles({ color, backgroundColor });
    return (
        <MuiLink
            onClick={() => {
                navigate(href);
            }}
            underline="none"
        >
            <Box className={classes.footer}>
                {href === '#vileo' ? (
                    <img src={arrow} className={classes.icon} alt="Arrow" />
                ) : (
                    <img
                        src={whiteArrow}
                        className={classes.icon}
                        alt="White Arrow"
                    />
                )}
                {footerTitle}
            </Box>
        </MuiLink>
    );
};
export default Footer;

Footer.propTypes = {
    color: PropTypes.string,
    backgroundColor: PropTypes.string.isRequired,
    footerTitle: PropTypes.string,
    href: PropTypes.string,
};
