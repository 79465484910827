import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    footer: {
        color: props => props.color || theme.palette.common.white,
        backgroundColor: props => props.backgroundColor,
        alignItems: 'center',
        padding: theme.spacing(1, 16, 5, 16),
        height: '30px',
        fontSize: '14px',
        fontWeight: 415,
        lineHeight: '17px',
        letterSpacing: '0.05em',
        bottom: 0,
        right: 0,
        display: 'block',
        position: 'absolute',
        width: '100vw',
        transition: 'height .2s ease-in-out',
        '&:hover': {
            height: '60px',
            cursor: 'pointer',

            fontWeight: 415,

            letterSpacing: '0.05em',
            '& $icon': {
                height: '40px',
            },
            [theme.breakpoints.down('lg')]: {
                fontSize: '14px',
                lineHeight: '16px',
            },
            [theme.breakpoints.down('lg')]: {
                fontSize: '12px',
                lineHeight: '15px',
            },
            [theme.breakpoints.down('md')]: {
                fontSize: '10px',
                lineHeight: '12px',
            },
        },
    },
    icon: {
        marginBottom: theme.spacing(0),
        paddingTop: theme.spacing(1),
        paddingRight: theme.spacing(2),
        height: '30px',
        transition: 'height .2s ease-in-out',
    },
}));
